// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-theme-dotdev-src-views-project-template-index-tsx": () => import("./../node_modules/gatsby-theme-dotdev/src/views/ProjectTemplate/index.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-dotdev-src-views-project-template-index-tsx" */),
  "component---node-modules-gatsby-theme-dotdev-src-views-writing-post-index-tsx": () => import("./../node_modules/gatsby-theme-dotdev/src/views/WritingPost/index.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-dotdev-src-views-writing-post-index-tsx" */),
  "component---node-modules-gatsby-theme-dotdev-src-pages-404-js": () => import("./../node_modules/gatsby-theme-dotdev/src/pages/404.js" /* webpackChunkName: "component---node-modules-gatsby-theme-dotdev-src-pages-404-js" */),
  "component---node-modules-gatsby-theme-dotdev-src-pages-about-js": () => import("./../node_modules/gatsby-theme-dotdev/src/pages/about.js" /* webpackChunkName: "component---node-modules-gatsby-theme-dotdev-src-pages-about-js" */),
  "component---node-modules-gatsby-theme-dotdev-src-pages-index-js": () => import("./../node_modules/gatsby-theme-dotdev/src/pages/index.js" /* webpackChunkName: "component---node-modules-gatsby-theme-dotdev-src-pages-index-js" */),
  "component---node-modules-gatsby-theme-dotdev-src-pages-projects-js": () => import("./../node_modules/gatsby-theme-dotdev/src/pages/projects.js" /* webpackChunkName: "component---node-modules-gatsby-theme-dotdev-src-pages-projects-js" */),
  "component---node-modules-gatsby-theme-dotdev-src-pages-writings-js": () => import("./../node_modules/gatsby-theme-dotdev/src/pages/writings.js" /* webpackChunkName: "component---node-modules-gatsby-theme-dotdev-src-pages-writings-js" */)
}

